<template>
    <div
        :class="classes"
        role="separator"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ absolute?: boolean; light?: boolean }>();

const classes = computed(() => [
    'ui-divider',
    {
        'ui-divider--absolute': props.absolute,
        'ui-divider--light': props.light,
    },
]);
</script>

<style lang="scss">
.ui-divider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;

    @include media-min('sm') {
        margin: 20px 0;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background-color: $black-3;
    }

    &--absolute {
        &::before,
        &::after {
            position: absolute;
            width: 100vw;
        }
    }

    &--light {
        &::before,
        &::after {
            height: 1px;
            background-color: $light-gray;
        }
    }
}
</style>
