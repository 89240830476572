<template>
    <vue-final-modal
        v-model="modelValue"
        :class="[
            `ui-modal ui-modal--${size}`,
            { 'ui-modal--smaller': isSmallerThanSize },
        ]"
        content-class="ui-modal__wrapper"
        overlay-class="ui-modal__overlay"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
        :z-index-fn="({ index }) => 100 + 2 * index"
    >
        <UiButton
            class="ui-modal__close-button"
            variant="secondary"
            shape="icon"
            icon-left="times"
            @click="modelValue = false"
        />

        <div class="ui-modal__header">
            <UiTitle
                class="ui-modal__title"
                type="h4"
                :text="title"
                :title="title"
            />

            <UiCaption
                v-if="caption"
                :text="caption"
            />
        </div>

        <UiDivider :class="{ 'mb-0': noDividerMargin }" />

        <div class="ui-modal__content">
            <slot />
        </div>

        <template v-if="$slots.footer">
            <UiDivider :class="{ 'mt-0': noDividerMargin }" />

            <div class="ui-modal__footer">
                <slot name="footer" />
            </div>
        </template>
    </vue-final-modal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import { useBreakpoints } from '@/composables';
import { Breakpoint } from '@/interfaces/utils';
import UiButton from './UiButton.vue';
import UiTitle from './UiTitle.vue';
import UiCaption from './UiCaption.vue';
import UiDivider from './UiDivider.vue';

interface Props {
    title?: string;
    caption?: string;
    size?: Breakpoint;
    noDividerMargin?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    title: '',
    caption: '',
    size: 'md',
    noDividerMargin: false,
});

const modelValue = defineModel({ default: false });

const breakpoints = useBreakpoints();
const isSmallerThanSize = breakpoints.smaller(props.size);
</script>

<style lang="scss">
.ui-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    &__overlay {
        backdrop-filter: blur(5px);
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: $black-gray;
        border-radius: 30px;
        max-height: calc(100vh - 40px);
        width: 100%;
    }

    &--sm &__wrapper {
        max-width: 490px;
    }

    &--md &__wrapper {
        max-width: 660px;
    }

    &--lg &__wrapper {
        max-width: 1000px;
    }

    &__close-button {
        position: absolute;
        top: 20px;
        right: -60px;
        background-color: rgb(255 255 255 / 10%) !important;
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 36px 60px 0;
    }

    &__title {
        @include text-clamp(2);
    }

    &__content {
        @include scrollable(
            $black-gray,
            $stable: false,
            $vertical-offset: 20px
        );
    }

    &__footer {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 10px 30px 30px;

        @include media-min('sm') {
            grid-template-columns: repeat(auto-fit, 160px);
        }
    }

    &--smaller {
        align-items: flex-end;

        .ui-modal__wrapper {
            max-width: unset;
            border-radius: 30px 30px 0 0;
        }

        .ui-modal__close-button {
            right: 20px;
        }

        .ui-modal__header {
            padding: 36px 30px 0;
        }

        .ui-modal__title {
            margin-right: 50px;
        }
    }
}
</style>
