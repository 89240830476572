<template>
    <UiModal
        v-model="modelValue"
        :title="t('update-info-modal.title')"
        :caption="t('update-info-modal.caption')"
        :z-index-fn="() => 1000"
        @before-close="handleClose"
    >
        <div class="update-info-modal__content">
            <p>{{ $t('update-info-modal.announcement') }}</p>

            <ol class="update-info-modal__list">
                <li>
                    <strong>
                        {{ $t('update-info-modal.features.gallery.title') }}
                    </strong>
                    <p>
                        {{
                            $t('update-info-modal.features.gallery.description')
                        }}
                    </p>
                </li>
                <li>
                    <strong>
                        {{ $t('update-info-modal.features.chat-panel.title') }}
                    </strong>
                    <p>
                        {{
                            $t(
                                'update-info-modal.features.chat-panel.description',
                            )
                        }}
                    </p>
                </li>
                <li>
                    <strong>
                        {{
                            $t(
                                'update-info-modal.features.meeting-recording.title',
                            )
                        }}
                    </strong>
                    <p>
                        {{
                            $t(
                                'update-info-modal.features.meeting-recording.description',
                            )
                        }}
                    </p>
                </li>
                <li>
                    <strong>
                        {{
                            $t('update-info-modal.features.session-rooms.title')
                        }}
                    </strong>
                    <p>
                        {{
                            $t(
                                'update-info-modal.features.session-rooms.description',
                            )
                        }}
                    </p>
                </li>
                <li>
                    <strong>
                        {{
                            $t(
                                'update-info-modal.features.participant-polls.title',
                            )
                        }}
                    </strong>
                    <p>
                        {{
                            $t(
                                'update-info-modal.features.participant-polls.description',
                            )
                        }}
                    </p>
                </li>
                <li>
                    <strong>
                        {{
                            $t(
                                'update-info-modal.features.logging-system.title',
                            )
                        }}
                    </strong>
                    <p>
                        {{
                            $t(
                                'update-info-modal.features.logging-system.description',
                            )
                        }}
                    </p>
                </li>
            </ol>

            <p>{{ $t('update-info-modal.important-notice') }}</p>

            <p>{{ $t('update-info-modal.closing-note') }}</p>
        </div>

        <template #footer>
            <UiButton
                variant="secondary"
                :label="t('update-info-modal.button')"
                @click="modelValue = false"
            />
        </template>
    </UiModal>
</template>

<script setup lang="ts">
import { useI18n } from '@/i18n';
import { useNotifications } from '@/composables';
import { UiButton, UiModal } from '@/components/v2';
import { useLocalStorage } from '@vueuse/core';

const modelValue = defineModel({ default: false });

const { t } = useI18n();
const { removeNotification } = useNotifications();
const isUpdateInfoShown = useLocalStorage('isUpdateInfoShown', false);

const handleClose = () => {
    isUpdateInfoShown.value = true;
    removeNotification('update-info-notification');
};
</script>

<style scoped lang="scss">
.update-info-modal {
    &__content {
        display: flex;
        flex-direction: column;
        padding: 0 60px;
        gap: 25px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        li {
            display: flex;
            flex-direction: column;
        }
    }
}
</style>
