import {
    createRouter,
    RouteRecordRaw,
    RouterView,
    createWebHistory,
} from 'vue-router';
import i18n, {
    isSupportedLocale,
    setI18nLanguage,
    getUserLocale,
} from '@/i18n';
import { createLogger } from '@/utils';
import { useAuthStore } from '@/pinia/stores/auth';
import { useUserStore } from '@/pinia/stores/user';
import { useRoomStore } from '@/pinia/stores/room';

const AuthPage = () => import('@/views/AuthPage.vue');
const LobbyPage = () => import('@/views/LobbyPage.vue');
const WebinarPage = () => import('@/views/WebinarPage.vue');
const JitsiMeet = () => import('@/views/JitsiMeet.vue');
const MeetingsPage = () => import('@/views/MeetingsPage.vue');
const WebinarCreationPage = () => import('@/views/WebinarCreationPage.vue');
const ScheduleWebinar = () => import('@/views/WebinarSchedulingPage.vue');
const LoginForm = () => import('@/blocks/LoginForm.vue');
const RegisterForm = () => import('@/blocks/RegisterForm.vue');
const PasswordPage = () => import('@/views/PasswordPage.vue');
const PasswordNew = () => import('@/blocks/PasswordNewForm.vue');
const PasswordEdit = () => import('@/blocks/PasswordEditForm.vue');
const ProfileEditPage = () => import('@/views/ProfileEditPage.vue');
const EmailConfirmation = () => import('@/views/EmailConfirmation.vue');
const NotFound = () => import('@/views/NotFound.vue');
const PricingPage = () => import('@/views/PricingPage.vue');
const Organization = () => import('@/views/Organization.vue');
const OrganizationIndex = () =>
    import('@/blocks/Organization/OrganizationIndex.vue');
const OrganizationAnalytics = () =>
    import('@/blocks/Organization/OrganizationAnalytics.vue');

const logger = createLogger('router');

const routes: RouteRecordRaw[] = [
    {
        path: '/:locale?/',
        component: RouterView,
        async beforeEnter(to, _, next) {
            const locale = to.params.locale as string;

            if (isSupportedLocale(locale)) {
                await setI18nLanguage(locale);
                return next();
            }

            to.params.locale = getUserLocale();
            return next({ ...to, replace: true });
        },
        children: [
            {
                path: '',
                name: 'MeetingsPage',
                component: MeetingsPage,
            },
            {
                path: 'auth',
                name: 'AuthPage',
                component: AuthPage,
                redirect: ({ params }) => `/${params.locale}/auth/login`,
                children: [
                    {
                        path: 'login',
                        name: 'Login',
                        component: LoginForm,
                    },
                    {
                        path: 'register',
                        name: 'Register',
                        component: RegisterForm,
                    },
                ],
            },
            {
                path: 'email-confirmation/:type',
                name: 'EmailConfirmation',
                component: EmailConfirmation,
            },
            {
                path: 'password',
                name: 'Password',
                redirect: ({ params }) => `/${params.locale}/password/new`,
                component: PasswordPage,
                children: [
                    {
                        path: 'new',
                        name: 'PasswordNew',
                        component: PasswordNew,
                    },
                    {
                        path: 'edit',
                        name: 'PasswordEdit',
                        component: PasswordEdit,
                    },
                ],
            },
            {
                path: 'webinar/:id',
                name: 'Webinar',
                component: WebinarPage,
                async beforeEnter(to, _, next) {
                    const roomStore = useRoomStore();
                    const locale = to.params.locale || i18n.global.locale.value;

                    if (roomStore.meetingRoom?.id !== to.params.id) {
                        next({ name: 'NotFound', params: { locale } });
                    } else {
                        next();
                    }
                },
            },
            {
                path: 'jitsi/:id',
                name: 'Jitsi',
                redirect: ({ params }) => `/rooms/${params.id}/`,
            },
            {
                path: 'rooms/:id',
                name: 'Rooms',
                component: JitsiMeet,
            },
            {
                path: 'lobby/:id',
                name: 'Lobby',
                component: LobbyPage,
            },
            {
                path: 'create-webinar',
                name: 'CreateWebinar',
                component: WebinarCreationPage,
            },
            {
                path: 'schedule-webinar',
                name: 'ScheduleWebinar',
                component: ScheduleWebinar,
            },
            {
                path: 'profile',
                name: 'Profile',
                component: ProfileEditPage,
            },
            {
                path: 'organization',
                name: 'Organization',
                component: Organization,
                beforeEnter(to, from, next) {
                    const userStore = useUserStore();

                    if (userStore.isOrganizationAvailable) {
                        return next();
                    }

                    return next({ name: 'MeetingsPage' });
                },
                children: [
                    {
                        path: '',
                        name: 'OrganizationIndex',
                        component: OrganizationIndex,
                    },
                    {
                        path: 'analytics',
                        name: 'OrganizationAnalytics',
                        component: OrganizationAnalytics,
                    },
                ],
            },
            {
                path: 'pricing',
                name: 'Pricing',
                component: PricingPage,
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const FREE_ACCESS_PAGES = [
    'AuthPage',
    'Login',
    'Register',
    'Password',
    'PasswordNew',
    'PasswordEdit',
    'Lobby',
    'Jitsi',
    'Rooms',
    'EmailConfirmation',
    'NotFound',
];

router.beforeEach(async (to, from, next) => {
    if (to.hash.startsWith('#/')) {
        return next(to.hash.slice(1));
    }

    const authStore = useAuthStore();
    const userStore = useUserStore();

    const locale = isSupportedLocale(to.params.locale as string)
        ? to.params.locale
        : i18n.global.locale.value;

    const isGuest = userStore.isGuest;
    const authenticated = !isGuest && !!authStore.accessToken;

    if (to.name === 'Login' && !isGuest) {
        authStore.removeTokens();
    }

    await userStore.loadProfile();

    logger.log('guest', isGuest);
    logger.log('authenticated', authenticated);

    if (to.name === 'Webinar' && from.name !== 'Lobby') {
        return next({ name: 'Lobby', params: { id: to.params.id, locale } });
    }

    if (isGuest && to.name === 'Webinar') {
        return next();
    }

    if (!authenticated && !FREE_ACCESS_PAGES.includes(to.name as string)) {
        return next({ name: 'AuthPage', params: { locale } });
    }

    return next();
});

export default router;
