<template>
    <metainfo>
        <template #title="{ content, metainfo }">{{
            content || metainfo.title
        }}</template>
        <template #description="{ content, metainfo }">{{
            content || metainfo.description
        }}</template>
        <template #og(title)="{ content, metainfo }">{{
            content || metainfo.title
        }}</template>
        <template #og(description)="{ content, metainfo }">{{
            content || metainfo.description
        }}</template>
        <template #og(url)="{ content, metainfo }">{{
            content || metainfo.url
        }}</template>
        <template #og(image)="{ content, metainfo }">{{
            content || metainfo.image
        }}</template>
        <template #image="{ content, metainfo }">{{
            content || metainfo.image
        }}</template>
        <template #twitter(title)="{ content, metainfo }">{{
            content || metainfo.title
        }}</template>
        <template #twitter(description)="{ content, metainfo }">{{
            content || metainfo.description
        }}</template>
        <template #twitter(image)="{ content, metainfo }">{{
            content || metainfo.image
        }}</template>
    </metainfo>

    <VApp>
        <VMain>
            <RouterView />
            <UpdateInfoModal />
        </VMain>

        <AppNotifications />

        <ModalsContainer />

        <LoadingScreen />
    </VApp>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import { ModalsContainer } from 'vue-final-modal';
import AppNotifications from '@/blocks/AppNotifications.vue';
import UpdateInfoModal from './blocks/UpdateInfoModal.vue';

const LoadingScreen = defineAsyncComponent(
    () => import('@/blocks/LoadingScreen.vue'),
);
</script>

<style lang="scss">
@import './assets/styles/styles.scss';
</style>
